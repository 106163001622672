import Footer from "./components/Footer";
import Main from "./components/Main";

function App() {
  return (
    <div className="min-h-screen bg-black/50 flex flex-col text-white">
      <Main />
      <Footer />
    </div>
  );
}

export default App;
