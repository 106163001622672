import React from 'react'

const Main = () => {
  return (
    <main className="container mx-auto px-6 pt-16 flex-1 text-center"> 
        <h1 className="text-2xl md:text-4xl lg:text-6xl uppercase">
            Welcome to
        </h1>
        <h2 className='text-3xl md:text-6xl lg-text-8xl font-black uppercase mb-8'>
            The Random Things!
        </h2>

        <div className='text-lg md:text-2xl lg:text-3xl py-2 px-4 md:py-4 md:px-10 lg:py-6 lg:px-12 w-fit mx-auto mb-8 rounded-lg'>
            Join to us today!
        </div>

        <form action="https://www.getrevue.co/profile/haksiu/add_subscriber" method='post' target='_blank' rel="noreferrer">
            <div className='flex flex-col md:flex-row justify-center mb-4'>
                <input autocomplete="off" placeholder='Your email...' type="email" name="member[email]" className="outline-none text-lg md:text-2xl placeholder:text-gray-400 placeholder:italic py-4 px-6 md:px-10 lg:py-6 lg:px-12 bg-white bg-opacity-10 focus:bg-opacity-20 duration-150 rounded-lg mb-4 md:mb-0 md:rounded-tr-none md:rounded-br-none" />
                <input type="submit" name="member[subscribe]" value="Join!" className="hover:opacity-75 md:rounded-tl-none md:rounded-bl-none bg-[#ba2ff1] rounded-lg text-lg md:text-2xl py-4 px-6 md:px-10 lg:py-6 lg:px-12 cursor-pointer duration-150" />
            </div>

            <div className="opacity-75 italic">
						By subscribing, you agree with Revue's <a rel="noreferrer" target="_blank" href="https://www.getrevue.co/terms" className="hover:opacity-80 duration-150">Terms of Service</a> and <a rel="noreferrer" target="_blank" href="https://www.getrevue.co/privacy" className="hover:opacity-80 duration-150">Privacy Policy</a>.
					</div>
        </form>
    </main>
  )
}

export default Main
