export default function Footer() {
    return (
        <footer className="container mx-auto p-6 flex flex-col md:flex-row items-center justify-between">
        <p>Website made by <a href="https://haksiu.pages.dev/" target="_blank" rel="noreferrer">Adrian</a></p>

        <div className="flex -mx-6">
          <a href="#" rel="noreferrer" className="mx-3 hover:opacity-80 duration-150">About</a> |
          <a href="https://www.getrevue.co/privacy" target="_blank" rel="noreferrer" className="mx-3 hover:opacity-80 duration-150">Privacy</a> |
          <a href="#" className="mx-3 hover:opacity-80 duration-150">Contact</a>
        </div>
      </footer>
    )
}